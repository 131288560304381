@import '@/core/tamagoshi/scss';

.container {
  background-color: $v100;
  color: $n800;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  position: relative;

  @include size-below('md') {
    padding: 20px 16px;
    justify-content: flex-start;
  }

  .button {
    position: relative;
    min-width: 144px;
    max-height: 48px;
    padding-left: 42px;

    @include size-above('md') {
      position: absolute;
      right: 0;
    }
  }
}

.block {
  display: flex;
  align-items: center;
  position: relative;

  @include size-above('md') {
    padding-left: 348px;
    width: 1060px;
    margin-right: 0;
  }
}

.text {
  width: 300px;

  @include size-below('md') {
    width: auto;
  }
}

.title {
  @include text('body1', 'bold');
  display: inline-block;
  width: 100%;
}

.subTitle {
  @include text('body2', 'semiBold');
  display: inline-block;
  width: 100%;
}

.comment {
  @include text('tiny', 'regular');
  color: $n700;
  display: inline-block;
  width: 100%;

  @include size-above('md') {
    @include text('body2', 'regular');
  }
}

.icon {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 6px;
}

.buttonBlock {
  width: 563px;
  display: flex;
  align-items: center;
  position: relative;

  @include size-below('lg') {
    width: 479px;
  }

  @include size-below('md') {
    width: inherit;
  }
}
